import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"

import HeaderBackground from "components/HeaderBackground"

import { Content, Baner, Grid, File } from "page_components/subpages"
import { Icons, PackagesContent } from "page_components/packages"

const Packages = ({ location }) => {
  const title = "Pakiety wykończeniowe"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
      mainClass="narrow-layout"
    >
      <HeaderBackground
        title={title}
        header="Pakiety wykończeniowe<br/> “pod klucz”"
        description="Wspólnie z naszym partnerem, firmą Rednet, przygotowaliśmy pakiety wykończeniowe, dopasowane do różnych potrzeb."
        background={
          require("assets/images/packages/packages-hero-background.jpg").default
        }
      />
      <Content
        header="Twoje miejsce na ziemi"
        description="Zakup mieszkania to początek pięknej przygody z wykańczaniem jego wnętrz, aby faktycznie stało się ono Twoim miejscem na ziemi.<br/><br/>Realizujemy wszystkie etapy prac - od wykonania projektu, przez zakup materiałów, wykonanie prac remontowo-wykończeniowych, zakup, transport i montaż mebli i elementów wyposażenia, aż do przekazania Państwu kluczy oraz pisemnej 24-miesięcznej gwarancji na wykonaną usługę. Maksymalne zaangażowanie naszego zespołu i minimalny angaż Państwa czasu."
        image={
          require("assets/images/packages/packages-content-image.jpg").default
        }
      />
      <PackagesContent />
      <File
        header="Wykończ mieszkanie dla siebie lub na wynajem."
        file={withPrefix("/Pakiety-wykonczeniowe_Trust-Investment.pdf")}
        image={
          require("assets/images/packages/packages-file-poster.png").default
        }
      />
      <Icons />
      <Grid
        data={[
          {
            logo: require("assets/images/packages/logo-rednet.svg").default,
            subHeader:
              "REDNET Dom to lider aranżacji i wykończenia wnętrz w Polsce.",
            description:
              "W ciągu 17 lat działalności firma zrealizowała tysiące projektów, zarówno dla klientów indywidualnych, jak i funduszy inwestycyjnych.",
            image: require("assets/images/packages/packages-grid-image.jpg")
              .default,
          },
        ]}
      />
      <Baner
        header="Porozmawiajmy<br/> o wykończeniu pod klucz"
        image={
          require("assets/images/packages/packages-baner-background.jpg")
            .default
        }
        defaultMessage="Chcę poznać ofertę pakietów wykończeniowych. Proszę o kontakt."
        withSelect
      />
    </Layout>
  )
}

export default Packages
