import "./styles.scss"

import React from "react"

const data = [
  {
    image: require("assets/images/packages/package-economy.jpg").default,
    color: "#ECE7D9",
    title: "Economy",
    header: "Idealne na inwestycję",
    description:
      "Bardzo szybki projekt na wyselekcjonowanych i sprawdzonych materiałach wykończeniowych z gotowym projektem łazienki na założeniach bez wnoszenia poprawek.",
    price: "Już od 999 zł/m2 brutto",
  },
  {
    image: require("assets/images/packages/package-optimal.jpg").default,
    color: "#DAD1B6",
    title: "Optimal+",
    header: "Mieszkanie na start",
    description:
      "To propozycja z szeroką bazą materiałów wykończeniowych z rozszerzonym zakresem prac wykończeniowych w łazience m.in.: przeróbki wodno-kanalizacyjne, lustro wklejane między płytki i przeróbki elektryczne z tym związane, układanie płytek.",
    info: "Możliwość pomniejszenia pakietu na: OPTIMAL.",
    price: "Już od 1 299 zł/m2 brutto",
  },
  {
    image: require("assets/images/packages/package-standard.jpg").default,
    color: "#DAD1B6",
    title: "Standard+",
    header: "Idealny dla rodzin",
    description:
      "Duża baza materiałów wykończeniowych z rozszerzonym zakresem prac wykończeniowych w łazience m.in.: przeróbki wodno-kanalizacyjne, lustro wklejane między płytki i przeróbki elektryczne z tym związane, układanie płytek do sufitu. Oprócz przeróbek możesz tu znaleźć podłogi drewniane, podłogi winylowe czy drzwi bezprzylgowe.",
    info: "Możliwość pomniejszenia pakietu na: STANDARD.",
    price: "Już od 1 879 zł/m2 brutto",
  },
  {
    image: require("assets/images/packages/package-exclusive.jpg").default,
    color: "#000000",
    title: "Exclusive",
    header: "Wszystko czego potrzebujesz",
    description: "Pakiet obejmuje najszerszą i ekskluzywną bazę materiałów:",
    list: [
      "Płytki wielkoformatowe.",
      "Prysznic z brodzikiem i prysznic walk-in w cenie.",
      "Gładzenie ścian i sufitów.",
      "W łazience przeróbki wodno-kanalizacyjne, lustro wklejane między płytki i przeróbki elektryczne z tym związane, układanie płytek do sufitu.",
    ],
    price: "Już od  2 668 zł/m2 brutto",
  },
]

const PackagesContent = () => {
  return (
    <section className="packages-content">
      <div className="container-fluid">
        <h3 className="packages-content__header">Wybierz pakiet dla siebie</h3>
        {data?.map((item, index) => (
          <div className="packages-content__item" key={index}>
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="packages-content__item-image">
                  <img src={item?.image} alt="" />
                  <span style={{ backgroundColor: item?.color }} />
                </div>
              </div>

              <div className="col-md-9">
                <div className="packages-content__item-inner">
                  <h3>
                    {item?.title} <span>{item?.price}</span>
                  </h3>
                  <h4>{item?.header}</h4>
                  <p>{item?.description}</p>
                  {item?.list && (
                    <ul>
                      {item?.list.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  )}
                  {item?.info && <h5>{item?.info}</h5>}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default PackagesContent
