import "./styles.scss"

import React from "react"

const data = [
  {
    icon: require("assets/images/packages/packages-icon-1.svg").default,
    header: "Transparentność kosztów",
  },
  {
    icon: require("assets/images/packages/packages-icon-2.svg").default,
    header: "Oszczędność VAT 8%<br/> i gwarancja ceny",
  },
  {
    icon: require("assets/images/packages/packages-icon-3.svg").default,
    header: "Indywidualny projekt",
  },
  {
    icon: require("assets/images/packages/packages-icon-4.svg").default,
    header: "Redukcja stresu<br/> i oszczędność czasu",
  },
  {
    icon: require("assets/images/packages/packages-icon-5.svg").default,
    header: "Nadzór nad ekipą<br/> wykończeniową",
  },
  {
    icon: require("assets/images/packages/packages-icon-6.svg").default,
    header: "Koordynacja<br/> podwykonawców",
  },
  {
    icon: require("assets/images/packages/packages-icon-7.svg").default,
    header: "24-miesięczny<br/> okres rękojmi",
  },
  {
    icon: require("assets/images/packages/packages-icon-8.svg").default,
    header: "Terminowy odbiór<br/> mieszkania",
  },
]

const Icons = () => {
  return (
    <section className="packages-icons">
      <div className="container-fluid">
        <h3>
          Dlaczego warto skorzystać
          <br /> z pomocy naszych ekspertów?
        </h3>
        <div className="row">
          {data?.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-6" key={index}>
              <div className="packages-icons__item">
                <div>
                  <img src={item?.icon} alt="" />
                </div>
                <h4 dangerouslySetInnerHTML={{ __html: item?.header }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Icons
